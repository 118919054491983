import React, { useContext, useState, useEffect } from 'react'
import styled from 'styled-components'
import Avatar from 'react-avatar'
import { Link } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import { FaBars } from 'react-icons/fa'
import { useAuth0 } from '@auth0/auth0-react'
import { SideNavLinks } from './SideLink'
import logo from '../../../assets/images/OrigoHealthLogo2.png'
import * as ROUTES from '../../NavigationComponent/constants'
import { AuthContext } from '../../Auth/Auth'
import {
  checkIsProvider,
  checkIsAdmin,
  getNameInitials,
  checkIsSsp,
  checkIsPatient,
} from '../../../utils/helpers'

export const Layout = ({ header, children }) => {
  const [toggleNav, setToggleNav] = useState(false)
  const [userType, setUserType] = useState('');
  const userMetadata = useContext(AuthContext)
  const { user } = useAuth0()
  const isProvider = checkIsProvider(userMetadata)
  const isAdmin = checkIsAdmin(userMetadata)
  const isSsp = checkIsSsp(userMetadata)
  const isPatient = checkIsPatient(userMetadata)
  const initials = React.useMemo(() => getNameInitials(user.name), [user.name])
  const { logout } = useAuth0()

	useEffect(() => {
		// const storedUserType = localStorage.getItem('userType');
		if (isProvider) 
        	setUserType('Provider')
      	else if (isAdmin)
        	setUserType('Admin')
      	else if (isSsp)
        	setUserType('Ssp')
      	else if (isPatient)
        	setUserType('Patient')
		else setUserType('NA')
		localStorage.setItem('userType', userType);	
	}, [isProvider, isSsp, isPatient, isAdmin, userType]);
		  
  return (
    <>
      <TopNav>
        <div className="container">
          <div
            style={{
              display: 'flex',
              justifyContent: 'left',
              alignItems: 'left',
            }}
          >
          	<NavLink to={ROUTES.LOGOUT}
    	      onClick={() => logout()}
        	  className="sidenav__items__item sidenav__items__item--exit"
    	    >
          		Logout
	        </NavLink>

          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Link to={ROUTES.HOME}>
              <img
                src={logo}
                alt=""
                style={{
                  width: '100%',
                  objectFit: 'contain',
                  maxWidth: '150px',
                }}
              />
            </Link>
            <HamburgerWrapper>
              <FaBars onClick={() => setToggleNav(!toggleNav)} />
            </HamburgerWrapper>
          </div>
        </div>
      </TopNav>
      <Body>
        <SideNav active={toggleNav} onClick={() => setToggleNav(false)}>
          <div className="sidenav" onClick={e => e.stopPropagation()}>
            <div>
              <Link to={ROUTES.USER_PROFILE}>
                <div className="sidenav__profile mb-6">
                  <Avatar
                    name={initials}
                    value={initials}
                    size="70px"
                    textSizeRatio={2}
                    round
                  />

                  <p className="sidenav__profile__name">{user.name}</p>
                  <p className="sidenav__profile__profession">{userType}
                  </p>
                </div>
              </Link>

              <SideNavLinks toggleNav={toggleNav} setToggleNav={setToggleNav} />
            </div>
          </div>
        </SideNav>

        <ContentContainer>
          <Content>{children}</Content>
        </ContentContainer>
      </Body>
    </>
  )
}

const TopNav = styled.div`
  position: fixed;
  width: 100vw;
  height: 80px;
  background-color: var(--white-10);
  z-index: 3;

  display: flex;
  align-items: center;
  border-bottom: 1px solid #eee;

  .topnav__nav-items {
    display: flex;
    margin-left: auto;
    align-items: center;

    .nav-item {
      padding: 16px;
      cursor: pointer;
      color: var(--black);
      text-decoration: none;

      &--menu {
        margin-left: 24px;
      }
    }
  }

  @media (min-width: 992px) {
    .topnav__search {
      display: block !important;
    }
    .topnav__nav-items {
      .nav-item {
        padding: 16px 24px;

        &--menu {
          margin-left: 32px;
        }
      }
    }
  }
`

const Body = styled.div`
  @media (min-width: 992px) {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
  }
`

const SideNav = styled.div`
  left: ${({ active }) => (active ? '0px' : '-100%')};
  background-color: rgba(0, 0, 0, 0.25);
  transition: ease-in-out all 0.15s;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 1;
  top: 0;
  margin-top: 80px;

  .sidenav {
    height: calc(100vh - 80px);
    background-color: #319795;
    transition: ease-in-out 0.25s all;
    overflow-y: auto;
    width: 250px;
    font-size: 14px;

    .sidenav__profile {
      padding-top: 30px;
      line-height: 1.6;
      text-align: center;
      color: #f4f6f6;

      &__avatar {
        width: 60px;
        height: 60px;
        position: relative;
        margin: 0px auto 12px auto;
        border-radius: 100%;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;

        & h2 {
          color: #444;
        }
      }

      &__name {
        font-weight: 500;
        font-size: 16px;
        margin-bottom: 0;
      }
    }

    .sidenav__items {
      &__item {
        padding: 12px;
        display: flex;
        align-items: center;
        color: #f4f6f6;
        text-decoration: none;
        padding-left: 30px;

        &--exit {
          background-color: #a50104;
          color: #fff;
          margin-bottom: 20px;
        }

        &--exit:hover {
          background-color: #c04d4f !important;
        }

        &--active {
          background-color: #38b2ac;
        }

        &:hover {
          background-color: #38b2ac;
        }

        .icon {
          color: #f4f6f6;
        }

        .name {
          line-height: 1.4;
          text-align: left;
          margin-left: 10px;
        }

        &.active {
          .name {
            font-weight: 500;
          }
        }

        // &:last-child {
        //   border-bottom: solid 1px var(--black-10);
        // }
      }
    }

    .sidenav__footer {
      p {
        line-height: 1.6;
        font-size: 14px;
      }

      div {
        width: 100%;
        height: 50px;
        display: grid;
        border-radius: 6px;
        align-items: center;
        border: solid 1px var(--black-20);
        grid-template-columns: calc(100% - 53px) 54px;

        span {
          height: 100%;
          display: flex;
          overflow: hidden;
          padding-left: 16px;
          padding-right: 16px;
          border-right: solid 1px var(--black-20);

          a {
            width: 100%;
            margin: auto;
            font-size: 14px;
            color: var(--blue);
          }
        }
      }
    }
  }

  @media (min-width: 992px) {
    background-color: transparent;
    grid-column-start: 1;
    grid-column-end: 2;
    position: relative;
    height: auto;
    width: 100%;
    left: auto;

    .sidenav {
      width: 100%;
    }
  }
`

const ContentContainer = styled.div`
  // background-color: var(--white-10);
  // box-shadow: var(--shadow);
  // border-radius: 6px;
  // padding: 16px;
  // padding: 30px;
  height: calc(100vh);
  background-color: #f7f8f8;
  overflow-y: auto;

  @media (min-width: 992px) {
    grid-column-start: 2;
    grid-column-end: 6;
  }
`

const Content = styled.div`
  position: relative;
  background-color: var(--white-10);
  padding: 50px 20px;
  margin-top: 80px;
  min-height: calc(100vh - 80px);
`

const HamburgerWrapper = styled.div`
  display: none;

  @media (max-width: 992px) {
    display: block;
  }
`
