import React, { lazy, Suspense, useContext } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import * as ROUTES from './constants'
import { AuthContext } from '../Auth/Auth'
import { Loading } from '../Loading/Loading'
import Registration from '../Registration/Registration'

const Home = lazy(() => import('../DashBoards/DashBoardComponents/Home'))
const HomeMap = lazy(() => import('../DashBoards/DashBoardComponents/HomeMap'))

const ChatAI= lazy(() => import('../DashBoards/DashBoardComponents/ChatAI'))
const ToolsDashboard = lazy(() => import('../DashBoards/DashBoardComponents/ToolsDashboard'))

const Connectors = lazy(() => import('../DashBoards/DashBoardComponents/connectors'))
const Services = lazy(() => import('../DashBoards/DashBoardComponents/services'))
const Transformers = lazy(() => import('../DashBoards/DashBoardComponents/transformers'))
const Jobs = lazy(() => import('../DashBoards/DashBoardComponents/jobs'))
const Recipes = lazy(() => import('../DashBoards/DashBoardComponents/recipes'))
const RecipeSteps = lazy(() => import('../DashBoards/DashBoardComponents/recipe-steps'))
const Triggers = lazy(() => import('../DashBoards/DashBoardComponents/triggers'))
const ContentCatalog = lazy(() => import('../DashBoards/DashBoardComponents/content-catalog'))
const ImageCatalog = lazy(() => import('../DashBoards/DashBoardComponents/image-catalog'))
const ContentAssociations = lazy(() => import('../DashBoards/DashBoardComponents/content-associations'))
const ContentCategories = lazy(() => import('../DashBoards/DashBoardComponents/content-categories'))
const ContentFacades = lazy(() => import('../DashBoards/DashBoardComponents/content-facades'))
const ContentIndices = lazy(() => import('../DashBoards/DashBoardComponents/content-indices'))
const ContentTemplates = lazy(() => import('../DashBoards/DashBoardComponents/content-templates'))
const ContentTypes = lazy(() => import('../DashBoards/DashBoardComponents/content-types'))
const ContentDescriptors = lazy(() => import('../DashBoards/DashBoardComponents/content-descriptors'))

const Appointments = lazy(() =>
  import('../DashBoards/DashBoardComponents/Appointments')
)
const PrivateRoute = lazy(() => import('./PrivateRoute'))
const AppointmentsCalendar = lazy(() =>
  import('../DashBoards/DashBoardComponents/AppointmentsCalendar')
)
const ProviderAppointmentsCalendar = lazy(() =>
  import('../DashBoards/DashBoardComponents/ProviderAppointmentsCalendar')
)
const VisitsCalendar = lazy(() =>
  import('../DashBoards/DashBoardComponents/VisitsCalendar')
)
const Labs = lazy(() => import('../DashBoards/DashBoardComponents/Labs'))
const Patients = lazy(() =>
  import('../DashBoards/DashBoardComponents/Patients')
)
const Payments = lazy(() =>
  import('../DashBoards/DashBoardComponents/Payments')
)
const Prescriptions = lazy(() =>
  import('../pages/Medications/Prescriptions')
)
const Providers = lazy(() =>
  import('../pages/Providers/Providers')
)
const EhxSsps = lazy(() =>
  import('../pages/Providers/EhxSsps')
)
const Manage_prescriptions = lazy(() =>
  import('../pages/Medications/PrescribePage')
)
const Pharmacists = lazy(() =>
  import('../DashBoards/DashBoardComponents/Pharmacists')
)
const CareTeams = lazy(() =>
  import('../DashBoards/DashBoardComponents/CareTeam')
)
const ManageCareTeam = lazy(() =>
  import('../DashBoards/DashBoardForms/ManageCareTeam')
)
const Referrals = lazy(() =>
  import('../DashBoards/DashBoardComponents/Referrals')
)
const HealthCareWorkers = lazy(() =>
  import('../DashBoards/DashBoardComponents/HealthCare')
)

const Visits = lazy(() => import('../DashBoards/DashBoardComponents/Visits'))
const Documents = lazy(() => import('../pages/UserProfile/Documents'))
const Subscribers = lazy(() =>
  import('../DashBoards/DashBoardComponents/Subscribers')
)

const CreateEhx = lazy(() => import('../DashBoards/DashBoardForms/CreateEhx'))
const CreateEhxPoc = lazy(() =>
  import('../DashBoards/DashBoardForms/CreateEhxPoc')
)
const EhxLabForm = lazy(() => import('../DashBoards/DashBoardForms/EhxLabForm'))
const EhxPatientForm = lazy(() =>
  import('../DashBoards/DashBoardForms/EhxPatientForm')
)
const EhxPaymentForm = lazy(() =>
  import('../DashBoards/DashBoardForms/EhxPaymentForm')
)
const EhxPrescriptionForm = lazy(() =>
  import('../pages/Medications/EhxPrescriptionForm')
)
const EhxProviderForm = lazy(() =>
  import('../DashBoards/DashBoardForms/EhxProviderForm')
)
const EhxProviderScheduleForm = lazy(() =>
  import('../DashBoards/DashBoardForms/EhxProviderScheduleForm')
)
const EhxUserProfileForm = lazy(() =>
  import('../DashBoards/DashBoardForms/EhxUserProfileForm')
)
const DashboardContent = lazy(() =>
  import('../DashBoards/DashboardContent/DashboardContent')
)
const EhxChatForm = lazy(() =>
  import('../DashBoards/DashBoardForms/EhxChatForm')
)
const ProviderSettings = lazy(() =>
  import('../pages/UserProfile/ProviderSettings')
)
const SubscriberSettings = lazy(() =>
  import('../pages/UserProfile/SubscriberSettings')
)
const Notifications = lazy(() => import('../pages/UserProfile/Notifications'))
const Accounts = lazy(() => import('../pages/UserProfile/Accounts'))
const UserProfile = lazy(() => import('../pages/UserProfile/UserProfile'))
const HealthFacilities = lazy(() =>
  import('../DashBoards/DashBoardComponents/HealthFacilities')
)
const EhxHealthCareFacilityForm = lazy(() =>
  import('../DashBoards/DashBoardForms/EhxHealthCareFacilityForm')
)

const EhxAppointmentFormPatientNew = lazy(() =>
  import('../DashBoards/DashBoardForms/EhxAppointmentForm/Patient/New')
)
const EhxAppointmentFormPatientEdit = lazy(() =>
  import('../DashBoards/DashBoardForms/EhxAppointmentForm/Patient/Edit')
)
const EhxAppointmentFormProviderNew = lazy(() =>
  import('../DashBoards/DashBoardForms/EhxAppointmentForm/Provider/New')
)
const EhxAppointmentFormProviderEdit = lazy(() =>
  import('../DashBoards/DashBoardForms/EhxAppointmentForm/Provider/Edit')
)

const EhxSubscriberForm = lazy(() =>
  import('../DashBoards/DashBoardForms/EhxSubscriberForm')
)
const EhxVisitFormPatient = lazy(() =>
  import('../DashBoards/DashBoardForms/EhxVisitFormPatient')
)
const EhxVisitFormProvider = lazy(() =>
  import('../DashBoards/DashBoardForms/EhxVisitFormProvider')
)
const Subscriptions = lazy(() =>
  import('../DashBoards/DashBoardComponents/Subscriptions')
)

const AdminAllUsers = lazy(() => import('../pages/Admin/Users'))
const AdminSubscriberDetails = lazy(() =>
  import('../pages/Admin/SubscriberDetails')
)

const AdminNewPatient = lazy(() =>
  import('../pages/Admin/adminForms/OnboardPatientForm')
)

const AdminNewProvider = lazy(() =>
  import('../pages/Admin/adminForms/OnboardProviderForm')
)

const AdminNewEhxSsp = lazy(() =>
  import('../pages/Admin/adminForms/OnboardEhxSspForm')
)

// CLAIMS
const ClaimsDashboard = lazy(() =>
  import('../DashBoards/DashBoardComponents/claims/ClaimsDashboard')
)

// PRIOR AUTHORIZATIONS
// const PADashboard = lazy(() =>
//   import('../DashBoards/DashBoardComponents/prior-authorizations/PADashboard')
// )

// UTILIZATION MANAGEMENT
const UMDashboard = lazy(() =>
  import('../DashBoards/DashBoardComponents/utilization-management/UMDashboard')
)

// FORMS MANAGEMENT
const FormsDashboard = lazy(() =>
  import('../DashBoards/DashBoardComponents/forms/FormsDashboard')
)
const ManagePatientForms = lazy(() =>
  import('../DashBoards/DashBoardComponents/forms/ManagePatientForms')
)

// VIEWERS
const ViewCareTeams = lazy(() => import('../DashBoards/DashboardContent/ViewCareTeams'))
const ViewDiagnostics = lazy(() => import('../DashBoards/DashboardContent/ViewDiagnostics'))
const ViewLabs = lazy(() => import('../DashBoards/DashboardContent/ViewLabs'))
const ViewMessages = lazy(() => import('../DashBoards/DashboardContent/ViewMessages'))
const ViewPfhs = lazy(() => import('../DashBoards/DashboardContent/ViewPfhs'))
const ViewPhrs = lazy(() => import('../pages/HealthRecords/ViewPhrs'))
const ViewReferrals = lazy(() => import('../DashBoards/DashboardContent/ViewReferrals'))
const ViewRxs = lazy(() => import('../pages/Medications/ViewRxs'))
const ViewTasks = lazy(() => import('../DashBoards/DashboardContent/ViewTasks'))

const CreatePhrs = lazy(() => import('../pages/HealthRecords/CreatePhrs'))
const CreateLabs = lazy(() => import('../pages/HealthRecords/CreateLabs'))
const CreateDiagnostics = lazy(() => import('../pages/HealthRecords/CreateDiagnostics'))
const CreateRxs = lazy(() => import('../pages/HealthRecords/CreateRxs'))
const CreateCareTeams = lazy(() => import('../pages/HealthRecords/CreateCareTeams'))
const CreatePfhs = lazy(() => import('../pages/HealthRecords/CreatePfhs'))
const CreateTasks = lazy(() => import('../pages/HealthRecords/CreateTasks'))
const CreateMessages = lazy(() => import('../pages/HealthRecords/CreateMessages'))
const CreateAmls = lazy(() => import('../pages/HealthRecords/CreateAmls'))

const ViewVitalSigns = lazy(() => import('../pages/HealthRecords/ViewVitalSigns'))
const ManageVitalSigns = lazy(() => import('../pages/HealthRecords/ManageVitalSigns'))
const ManagePhrs = lazy(() => import('../pages/HealthRecords/ManagePhrs'))

// const CreateCareTeams = lazy(() =>
//   import('../DashBoards/DashBoardForms/CreateCareTeam')
// )

const RouterConfig = ({ sessionObject, membershipType }) => {

//const userMetadata = useContext(AuthContext)

	//console.log('************ BAD CODE IN ROUTER CONFIG WITH HARDCODED USERMETADATA MUST BE REMOVED ************')
	const userMetadata = {"customerId":1,"membershipType":"3002","userId":520,"apiKey":"linkedin|rz1MycKLaZ","stripe":{"id":"cus_MvcUINmEfdcIFK","object":"customer","subscriptions":{"object":"list","data":[],"has_more":false,"total_count":0,"url":"/v1/customers/cus_MvcUINmEfdcIFK/subscriptions"}}}
	
	// if (userMetadata == null || userMetadata.membershipType == null)
// 	{
// 		console.log('fixing up routerconfig.usermeta.membershipType to 3000');
// 		userMetadata.membershipType = "3000";  
//     }
//    console.log('RouterConfig.usermeta - ' + JSON.stringify(userMetadata));

	return (
    	<Suspense fallback={<Loading />}>
      	<Switch>

        <Route path={ROUTES.REGISTER_PATIENT}>
          <Registration />
        </Route>

        <Route path={ROUTES.SUBSCRIBERS}>
          <Subscribers />
        </Route>
        <Route path={ROUTES.SUBSCRIPTIONS}>
          <Subscriptions />
        </Route>
        <Route path={ROUTES.VISITS_CALENDAR}>
          <VisitsCalendar />
        </Route>
        <Route path={ROUTES.PRESCRIPTIONS}>
          <Prescriptions />
        </Route>

        <Route path={ROUTES.LABS}>
          <Labs />
        </Route>
        <Route path={ROUTES.PAYMENTS}>
          <Payments />
        </Route>
        <Route path={ROUTES.CREATE_PROVIDER}>
          <EhxProviderForm />
        </Route>
        <Route path={ROUTES.CREATE_PATIENT}>
          <EhxPatientForm />
        </Route>

        {/**
         *  APPOINTMENTS RELATED ROUTES
         */}
         
        <Route path={ROUTES.CREATE_APPOINTMENT}>
            <EhxAppointmentFormPatientNew />

		{/*
          {parseInt(userMetadata.membershipType) !== 3000 ? (
            <EhxAppointmentFormProviderNew />
          ) : (
          )}

          {parseInt(userMetadata.membershipType) !== 3000 ? (
          ) : (

          */}

        </Route>
        <Route path={ROUTES.UPDATE_APPOINTMENT_PROVIDER}>
            <EhxAppointmentFormProviderEdit />
        </Route>
        <Route path={ROUTES.UPDATE_APPOINTMENT_PATIENT}>
            <EhxAppointmentFormPatientEdit />
        </Route>
        <Route path={ROUTES.APPOINTMENTS_CALENDAR}>
          <AppointmentsCalendar />
        </Route>
        <Route path={ROUTES.PROVIDER_APPOINTMENTS_CALENDAR}>
          <ProviderAppointmentsCalendar />
        </Route>
        <Route path={ROUTES.APPOINTMENTS}>
          <Appointments />
        </Route>

        <Route path={ROUTES.CREATE_PRESCRIPTION}>
          <EhxPrescriptionForm />
        </Route>
        <Route path={ROUTES.CREATE_LAB}>
          <EhxLabForm />
        </Route>
        <Route path={ROUTES.CREATE_PAYMENT}>
          <EhxPaymentForm />
        </Route>
        <Route path={ROUTES.UPDATE_PATIENT}>
          <EhxPatientForm />
        </Route>
        <PrivateRoute path={ROUTES.PATIENTS}>
          <Patients />
        </PrivateRoute>
        <Route path={ROUTES.UPDATE_VISIT}>
          {parseInt(userMetadata.membershipType) !== 3000 ? (
            <EhxVisitFormProvider />
          ) : (
            <EhxVisitFormPatient />
          )}
        </Route>
        <Route path={ROUTES.PHARMACISTS}>
          <Pharmacists />
        </Route>
        <Route path={ROUTES.CARE_TEAMS}>
          <CareTeams />
        </Route>
        <Route path={ROUTES.CREATE_CARE_TEAMS}>
          <CreateCareTeams />
        </Route>
        <Route path={ROUTES.MANAGE_CARE_TEAMS}>
          <ManageCareTeam />
        </Route>
        <Route path={ROUTES.REFERRAL}>
          <Referrals />
        </Route>
        <Route path={ROUTES.MANAGE_PRESCRIPTIONS}>
          <Manage_prescriptions />
        </Route>
        <Route path={ROUTES.HEALTHCARE_WORKERS}>
          <HealthCareWorkers />
        </Route>
        <Route path={ROUTES.VISITS}>
          <Visits />
        </Route>
        <Route path={ROUTES.MANAGE_SUBSCRIBER}>
          <EhxSubscriberForm />
        </Route>

        <Route path={ROUTES.MANAGE_LAB}>
          <EhxLabForm />
        </Route>
        <Route path={ROUTES.MANAGE_PAYMENT}>
          <EhxPaymentForm />
        </Route>

        {/* Healthcare facilites */}
        <Route path={ROUTES.UPDATE_HEALTHCARE_FACILITY}>
          <EhxHealthCareFacilityForm />
        </Route>
        <Route path={ROUTES.HEALTHCARE_FACILITY}>
          <HealthFacilities />
        </Route>

        <Route path={ROUTES.CHAT}>
          <EhxChatForm />
        </Route>

        {/* User Profile */}
        <Route
          path={ROUTES.USER_PROFILE}
          render={props => (
            <UserProfile {...props} sessionObject={sessionObject} />
          )}
        />

        {/* Providers */}
        <Route path={ROUTES.UPDATE_PROVIDER}>
          <EhxProviderForm />
        </Route>
        <Route path={ROUTES.PROVIDERS}>
          <Providers />
        </Route>

        <Route path={ROUTES.DOCUMENTS}>
          <Documents sessionObject={sessionObject} />
        </Route>

        <Route path={ROUTES.PROVIDER_SETTINGS}>
          <ProviderSettings />
        </Route>

        {/* SSPs 
        
        <Route path={ROUTES.ADMIN_SSP_DETAILS}>
          <EhxProviderForm />
        </Route>
        
        */}
        <Route path={ROUTES.ADMIN_LIST_SSPS}>
          <EhxSsps />
        </Route>

        {/*  Admin Routes  */}
        <Route exact path={ROUTES.ADMIN_NEW_PATIENT}>
          <AdminNewPatient />
        </Route>
        <Route exact path={ROUTES.ADMIN_NEW_PROVIDER}>
          <AdminNewProvider />
        </Route>
        <Route exact path={ROUTES.ADMIN_NEW_SSP}>
          <AdminNewEhxSsp />
        </Route>
        <Route path={ROUTES.ADMIN_SUBSCRIBER_DETAILS}>
          <AdminSubscriberDetails />
        </Route>
        <Route exact path={ROUTES.ADMIN_ALL_SUBSCRIBERS}>
          <AdminAllUsers />
        </Route>
        <Route path={ROUTES.NOTIFICATIONS}>
          <Notifications />
        </Route>
        <Route path={ROUTES.ACCOUNTS}>
          <Accounts />
        </Route>

        <Route path={ROUTES.CHAT_AI}>
          <ChatAI />
        </Route>

        <Route path={ROUTES.TOOLS_DASHBOARD}>
          <ToolsDashboard />
        </Route>

        {/* EHX TOOLS DASHBOARD ROUTES */}
        
        <Route path={ROUTES.CONNECTORS}><Connectors /></Route>
		<Route path={ROUTES.SERVICES}><Services /></Route>
		<Route path={ROUTES.TRANSFORMERS}><Transformers /></Route>
		<Route path={ROUTES.JOBS}><Jobs /></Route>
		<Route path={ROUTES.RECIPES}><Recipes /></Route>
		<Route path={ROUTES.RECIPE_STEPS}><RecipeSteps /></Route>
		<Route path={ROUTES.TRIGGERS}><Triggers /></Route>
		<Route path={ROUTES.CONTENT_CATALOG}><ContentCatalog /></Route>
		<Route path={ROUTES.IMAGE_CATALOG}><ImageCatalog /></Route>
		<Route path={ROUTES.CONTENT_ASSOCIATIONS}><ContentAssociations /></Route>	
		<Route path={ROUTES.CONTENT_CATEGORIES}><ContentCategories /></Route>
		<Route path={ROUTES.CONTENT_DESCRIPTORS}><ContentDescriptors /></Route>
		<Route path={ROUTES.CONTENT_FACADES}><ContentFacades /></Route>
		<Route path={ROUTES.CONTENT_INDICES}><ContentIndices /></Route>
		<Route path={ROUTES.CONTENT_TEMPLATES}><ContentTemplates /></Route>
		<Route path={ROUTES.CONTENT_TYPES}><ContentTypes /></Route>


        <Route path={ROUTES.MAP}>
          <HomeMap />
        </Route>

		{/* CLAIMS */}
        <Route path={ROUTES.CLAIMS}><ClaimsDashboard /></Route>

		{/* UTILIZATION MANAGEMENT */}
        <Route path={ROUTES.UTILIZATION_MANAGEMENT}><UMDashboard /></Route>

		{/* FORMS MANAGEMENT */}
        <Route path={ROUTES.ADMIN_FORMS_DASHBOARD}><FormsDashboard /></Route>
        <Route path={ROUTES.ADMIN_MANAGE_PATIENT_FORMS}><ManagePatientForms /></Route>

        <Route
          path={ROUTES.HOME}
          render={props => <Home {...props} membershipType={membershipType} />}
        />

        {/* 
        		Create EHX Entities 
		*/}

		<Route path={ROUTES.CREATE_SUBSCRIBERS}><FormsDashboard /></Route>
		<Route 
			path={ROUTES.CREATE_PHRS}
			render={props => <CreatePhrs {...props} sessionObject={sessionObject} />}
		/>
		<Route path={ROUTES.CREATE_LABS}><CreateLabs /></Route>
		<Route path={ROUTES.CREATE_DIAGNOSTICS}><CreateDiagnostics /></Route>
		<Route path={ROUTES.CREATE_RXS}><CreateRxs /></Route>
		<Route path={ROUTES.CREATE_TASKS}><CreateTasks /></Route>
		<Route path={ROUTES.CREATE_MESSAGES}><CreateMessages /></Route>
		<Route path={ROUTES.CREATE_PFHS}><CreatePfhs /></Route>
		<Route path={ROUTES.MANAGE_VITAL_SIGNS}><ManageVitalSigns /></Route>
		<Route path={ROUTES.MANAGE_PHRS}><ManagePhrs /></Route>
        
        
        {/* 
        		Viewers 

		<Route path={ROUTES.VIEW_PROVIDERS}><FormsDashboard /></Route>
		<Route path={ROUTES.VIEW_APPOINTMENTS}><FormsDashboard /></Route>
		<Route path={ROUTES.VIEW_VISITS}><FormsDashboard /></Route>
		<Route path={ROUTES.VIEW_PATIENTS}><FormsDashboard /></Route>
		<Route path={ROUTES.VIEW_SUBSCRIBERS}><FormsDashboard /></Route>
		<Route path={ROUTES.VIEW_LAB_FACILITIES}><FormsDashboard /></Route>
		<Route path={ROUTES.VIEW_HEATHCARE_FACILITIES}><FormsDashboard /></Route>
		<Route path={ROUTES.VIEW_PAYMENTS}><FormsDashboard /></Route>
        
        */}


		<Route path={ROUTES.VIEW_CARE_TEAMS}><ViewCareTeams /></Route>
		<Route path={ROUTES.VIEW_PRESCRIPTIONS}><ViewRxs /></Route>
		<Route path={ROUTES.VIEW_PHRS}><ViewPhrs /></Route>
		<Route path={ROUTES.VIEW_LABS}><ViewLabs /></Route>
		<Route path={ROUTES.VIEW_DIAGNOSTICS}><ViewDiagnostics /></Route>
		<Route path={ROUTES.VIEW_RXS}><ViewRxs /></Route>
		<Route path={ROUTES.VIEW_TASKS}><ViewTasks /></Route>
		<Route path={ROUTES.VIEW_MESSAGES}><ViewMessages /></Route>
		<Route path={ROUTES.VIEW_PFHS}><ViewPfhs /></Route>
		<Route path={ROUTES.VIEW_VITAL_SIGNS}><ViewVitalSigns /></Route>
        

        <Route path="/" exact>
          <Redirect to={ROUTES.HOME} />
        </Route>
      </Switch>
    </Suspense>
  )
}

export default RouterConfig
