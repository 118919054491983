import React, { createContext, useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Loading } from '../Loading/Loading'
import { setToken } from '../../services/index'
import { globalConfig } from '../../config'

export const AuthContext = createContext({ userMetadata: null })

export const Auth = props => {
  const [userMetadata, setUserMetaData] = useState(null)
  const [hasActiveSubscription, setHasActiveSubscription] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const { user, getAccessTokenSilently } = useAuth0()
	
  const getUserMetadata = async () => {
    try {
      setIsLoading(true)
      const baseURL = `${globalConfig.auth0.managementAPIUrl}`
      const userDetailsByIdUrl = `${baseURL}users/${user.sub}`
      const accessToken = await getAccessTokenSilently({
        audience: baseURL,
        scope: 'read:current_user',
      })
      const metadataResponse = await fetch(userDetailsByIdUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      const { user_metadata: userMeta } = await metadataResponse.json()
      if (userMeta) {
        setToken(`${userMeta.apiKey}:${userMeta.userId}`);
        setUserMetaData(userMeta);
        setHasActiveSubscription(true);
      }
    } catch (error) {
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getUserMetadata()
  }, [])

  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      {
      (hasActiveSubscription) ? (
        <AuthContext.Provider value={userMetadata}>
          {props.children}
        </AuthContext.Provider>
      )  : (<AuthContext.Provider value={userMetadata}>
      		 {props.children}
      		</AuthContext.Provider>
      		)
       }
    </>
  )
}
