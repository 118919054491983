// EHX Core Platform Dashboards
// export const TOOLS = '/tools'
export const TOOLS_DASHBOARD = '/tools/dashboard'

export const CONNECTORS = '/tools/connectors'
export const SERVICES = '/tools/services'
export const TRANSFORMERS = '/tools/transformers'
export const JOBS = '/tools/jobs'
export const RECIPES = '/tools/recipes'
export const RECIPE_STEPS = '/tools/recipe-steps'
export const TRIGGERS = '/tools/triggers'
export const CONTENT_CATALOG = '/tools/content-catalog'
export const IMAGE_CATALOG = '/tools/image-catalog'
// export const ASSOCIATIONS = '/tools/associations'
// export const CATEGORIES = '/tools/categories'
// export const DESCRIPTORS = '/tools/descriptors'
// export const FACADES = '/tools/facades'
// export const INDICES = '/tools/indices'
// export const TEMPLATES = '/tools/templates'
export const CONTENT_TYPES = '/tools/content-types'
export const CONTENT_ASSOCIATIONS = '/tools/content-associations'
export const CONTENT_CATEGORIES = '/tools/content-categories'
export const CONTENT_DESCRIPTORS = '/tools/content-descriptors'
export const CONTENT_FACADES = '/tools/content-facades'
export const CONTENT_INDICES = '/tools/content-indices'
export const CONTENT_TEMPLATES = '/tools/content-templates'



export const PATIENTS = '/patients'
export const PROVIDERS = '/providers'
export const APPOINTMENTS = '/appointments'
export const APPOINTMENTS_CALENDAR = '/appointments-calendar'
export const PROVIDER_APPOINTMENTS_CALENDAR = '/appointments-calendar-provider'
export const VISITS_CALENDAR = '/visits-calendar'
export const VISITS = '/visits'
export const PAYMENTS = '/payments'
export const LABS = '/labs'
export const PRESCRIPTIONS = '/prescriptions'
export const HOME = '/home'
export const REGISTER_PATIENT = '/register-patient'
export const CHAT = '/chat/:id'
export const CHAT_AI = '/chat-ai'
export const HEALTHCARE_FACILITY = '/heathcare-facilities'
export const SUBSCRIBERS = '/subscribers'
export const MAP = '/map'
export const SUBSCRIPTIONS = '/subscriptions'
export const PHARMACISTS = '/pharmacists'
export const CARE_TEAMS = '/care-teams'
export const REFERRAL = '/referral'
export const HEALTHCARE_WORKERS = '/healthcare-worker'
export const MANAGE_PRESCRIPTIONS = '/manage-prescriptions'
export const ADMIN = '/admin'
// Ehx Form Routes - Management
export const MANAGE_PROVIDER = '/manage-provider'
export const MANAGE_APPOINTMENT = '/manage-appointment'
export const MANAGE_CARE_TEAMS = '/manage-care-teams/:id'
export const MANAGE_VISIT = '/manage-visit'
export const MANAGE_PATIENT = '/manage-patient'
export const MANAGE_PRESCRIPTION = '/manage-prescription'
export const MANAGE_LAB = '/manage-lab'
export const MANAGE_PAYMENT = '/manage-payment'
export const MANAGE_HEATHCARE_FACILITY = '/manage-healthcare-facility'
export const MANAGE_SUBSCRIBER = '/manage-subscriber'
export const MANAGE_VITAL_SIGNS = '/manage-vital-signs'
export const MANAGE_PHRS = '/manage-phrs/:id'

// Ehx Form Routes - Create
export const CREATE_PROVIDER = '/create-provider'
export const CREATE_APPOINTMENT = '/appointments/create'
export const CREATE_CARE_TEAMS = '/create-care-teams'
export const CREATE_VISIT = '/create-visit'
export const CREATE_PATIENT = '/create-patient'
export const CREATE_PRESCRIPTION = '/create-prescription'
export const CREATE_LAB = '/create-lab'
export const CREATE_PAYMENT = '/create-payment'
export const CREATE_HEALTHCARE_FACILITY = '/create-healthcare-facility'
export const CREATE_SUBSCRIBERS = '/create-subscribers'
export const CREATE_PHRS = '/create-phrs'
export const CREATE_LABS = '/create-labs'
export const CREATE_DIAGNOSTICS = '/create-diagnostics'
export const CREATE_RXS = '/create-rxs'
export const CREATE_TASKS= '/create-tasks'
export const CREATE_MESSAGES = '/create-messages'
export const CREATE_PFHS = '/create-pfhs'


// Ehx Form Routes - Edit
export const UPDATE_APPOINTMENT_PROVIDER = '/appointments/provider/:id'
export const UPDATE_APPOINTMENT_PATIENT = '/appointments/patient/:id'
export const UPDATE_VISIT = '/visits/:id'
export const UPDATE_PATIENT = '/patients/:id'
export const UPDATE_PROVIDER = '/providers/:id'
export const UPDATE_HEALTHCARE_FACILITY = '/healthcare-facilities/:id'

// Ehx Form Routes - Viewer
export const VIEW_PROVIDERS = '/view-providers'
export const VIEW_APPOINTMENTS = '/view-appointments'
export const VIEW_CARE_TEAMS = '/view-care-teams/:id'
export const VIEW_VISITS = '/view-visits'
export const VIEW_PATIENTS = '/view-patient'
export const VIEW_PRESCRIPTIONS = '/view-prescriptions'
export const VIEW_LAB_FACILITIES= '/view-lab-facilities'
export const VIEW_PAYMENTS = '/view-payments'
export const VIEW_HEATHCARE_FACILITIES = '/view-healthcare-facilites'
export const VIEW_SUBSCRIBERS = '/view-subscribers'
export const VIEW_PHRS = '/view-phrs'
export const VIEW_LABS = '/view-labs'
export const VIEW_DIAGNOSTICS = '/view-daignostics'
export const VIEW_RXS = '/view-rxs'
export const VIEW_TASKS= '/view-tasks'
export const VIEW_MESSAGES = '/view-messages'
export const VIEW_PFHS = '/view-pfhs'
export const VIEW_VITAL_SIGNS = '/view-vital-signs'

// Ehx UserProfile routes
export const PROVIDER_SETTINGS = '/provider-settings'
export const SUBSCRIBER_SETTINGS = '/subscriber-settings'
export const NOTIFICATIONS = '/notifications'
export const LOGOUT = '/logout'
export const ACCOUNTS = '/accounts'
export const USER_PROFILE = '/user-profile'
export const DOCUMENTS = '/docs'

// Ehx Admin Routes
export const ADMIN_ALL_SUBSCRIBERS = '/admin/subscribers'
export const ADMIN_NEW_PATIENT = '/admin/patients/new'
export const ADMIN_NEW_PROVIDER = '/admin/provider/new'
export const ADMIN_SUBSCRIBER_DETAILS = '/admin/subscribers/:id'
export const ADMIN_FORMS_DASHBOARD = '/admin/forms-dashboard'
export const ADMIN_MANAGE_PATIENT_FORMS = '/admin/manage-patient-forms'
export const ADMIN_LIST_SSPS = '/admin/ssps'
export const ADMIN_NEW_SSP = '/admin/new-ssp'
export const ADMIN_SSP_DETAILS = '/admin/ssps/:id'

// Claims
export const CLAIMS = '/claims/dashboard'

// Prior Authorizations
//export const PRIOR_AUTHORIZATIONS = '/pa/dashboard'

// Utilization Management
export const UTILIZATION_MANAGEMENT = '/um/dashboard'
